<template>
  <b-card-code title="Modifier Semaine">
    <b-card-text>
      <span>Veuillez modifier les coordonnées du Semaine </span>
    </b-card-text>
    <!-- form -->
    <validation-observer
      v-if="semaines"
      ref="simpleRules"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Année Universitaire"
              label-for="Année Universitaire"
            >
              <validation-provider
                #default="{ errors }"
                name="Année Universitaire"
                rules="required"
              >
                <b-form-input
                  v-model="semaines.academic_year"
                  :state="errors.length > 0 ? false : null"
                  disabled
                  placeholder="Année Universitaire"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Semester"
              label-for="Semester"
            >
              <validation-provider
                #default="{ errors }"
                name="Semester"
                rules="required"
              >
                <b-form-input
                  v-model="semaines.semester"
                  :state="errors.length > 0 ? false : null"
                  disabled
                  placeholder="Semester"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="N° Semaine"
              label-for="N° Semaine"
            >
              <validation-provider
                #default="{ errors }"
                name="N° Semaine"
                rules="required"
              >
                <b-form-input
                  v-model="semaines.number_week"
                  :state="errors.length > 0 ? false : null"
                  disabled
                  placeholder="N° Semaine"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Diplôme"
              label-for="Diplôme"
            >
              <validation-provider
                #default="{ errors }"
                name="Diplôme"
                rules="required"
              >
                <v-select
                  v-model="semaines.diploma"
                  :clearable="false"
                  :options="diplomas"
                  label="name"
                  placeholder="Diplôme"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Type Semaine"
              label-for="Type Semaine"
            >
              <validation-provider
                #default="{ errors }"
                name="Type Semaine"
                rules="required"
              >
                <v-select
                  v-model="semaines.type_week"
                  :clearable="false"
                  :options="type_week"
                  label="type semaine"
                  placeholder="Type semaine"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <h5>Date début semaine</h5>
            <b-form-datepicker
              id="example-datepicker"
              v-model="semaines.start_date"
              class="mb-1"
              placeholder="Date début semaine"
            />
          </b-col>
          <b-col md="6">
            <h5>Date Fin Semaine</h5>
            <b-form-datepicker
              id="example-datepickerr"
              v-model="semaines.end_date"
              class="mb-1"
              placeholder="Date Fin semaine"
            />
          </b-col>
          <b-col md="6">
            <b-form-group
              label="description"
              label-for="description"
            >
              <validation-provider
                #default="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-input
                  v-model="semaines.description"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Libellé Abrégé"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="editSemaine"
            >
              Modifier
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,

  },
  data() {
    return {
      password: '',
      semaines: {},
      required,
      type_week: ['cours', 'ds', 'examen', 'seminiare', 'certification', 'revision', 'vacances', 'soutenance', 'mis a niveau', 'examen de rattrapage'],
      diplomas: [],
    }
  },
  created() {
    this.getSemaines()
    this.getDiplomas()
  },
  methods: {
    async getDiplomas() {
      this.load = 'true'
      const response = await axios.get('/api/diplomas/')
      this.diplomas = response.data
      this.load = 'false'
    },
    async getSemaines() {
      this.load = 'true'
      const { id } = this.$route.params
      const response = await axios.get(`/api/weeks/detail/${id}`)
      this.semaines = response.data
      this.load = 'false'
    },
    async editSemaine() {
      this.$refs.simpleRules.validate().then(async success => {
        const { id } = this.$route.params
        if (success) {
          try {
            await axios
              .put(
                `/api/weeks/update/${id}/`,
                {
                  type_week: this.semaines.type_week,
                  start_date: this.semaines.start_date,
                  end_date: this.semaines.end_date,
                  description: this.semaines.description,
                  diploma: this.semaines.diploma.id,
                },
              )
            this.$router.push('/weeks')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Semaines Modifié avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
